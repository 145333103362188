<template>
  <v-data-table
    :headers="headers"
    :items="profiles"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Compte admin
        </v-toolbar-title>
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs}">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.nom"
                      label="Nom"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.prenom"
                      label="Prénom"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-select
                      v-model="profileEdit.type_compte"
                      label="Type de compte"
                      :items="typeComptes"
                      item-text="text"
                      item-value="value"
                      single-line
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.email"
                      label="Email"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.password"
                      type="password"
                      label="Mot de passe"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darke-1"
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darke-1"
                @click="saveProfile"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{item}">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.logo_organisations="{item}">
      <v-img
        :lazy-src="item.logo_organisations"
        max-height="150"
        max-width="250"
        :src="item.logo_organisations"
      />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import profilAdmin from '@/services/compte-admins.js'
  export default {
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        headers: [
          { text: 'Actions', value: 'actions', sortable: false },
          {
            text: 'Nom',
            value: 'nom',
          },
          {
            text: 'Prénom',
            value: 'prenom',
          },
          {
            text: 'Type de compte',
            value: 'type_compte',
          },
          {
            text: 'Email',
            value: 'email',
          },
        ],
        profiles: [],
        editedIndex: -1,
        typeComptes: [
          {
            text: 'Administrateur',
            value: 'admin',
          },
          {
            text: 'Visiteur',
            value: 'visiteur',
          },
        ],
        profileEdit: {
          id: '',
          nom: '',
          prenom: '',
          type_compte: '',
          password: '',
          email: '',
        },
        profile: {
          nom: '',
          prenom: '',
          type_compte: '',
          password: '',
          email: '',
        },
      }
    },
    mounted () {
      console.log(this.editedIndex)
      profilAdmin.getUsersProfile().then(response => {
        this.profiles = response.data
      })
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter un profil' : 'Modifier un profil'
      },
    },
    methods: {
      ...mapActions(['changeThemeLayout']),
      saveProfile () {
        if (this.editedIndex > -1) {
          profilAdmin.updateUser(this.profileEdit).then(response => {
            console.log(response.data)
            profilAdmin.getUsersProfile().then(response => {
              this.profiles = response.data
            })
          }).catch(err => {
            console.log(err.data)
          })
        } else {
          profilAdmin.createUser(this.profileEdit).then(response => {
            console.log(response.data)
            profilAdmin.getUsersProfile().then(response => {
              this.profiles = response.data
            })
          }).catch(err => {
            console.log(err.data)
          })
        }
        this.close()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.profileEdit = Object.assign({}, this.profile)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.profiles.indexOf(item)
        this.profileEdit = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.profiles.indexOf(item)
        this.profileEdit = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        profilAdmin.deleteUser(this.profileEdit).then(response => {
          profilAdmin.getUsersProfile().then(response => {
            this.profiles = response.data
          })
        }).catch(err => {
          console.log(err.data)
        })
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.profileEdit = Object.assign({}, this.profile)
          this.editedIndex = -1
        })
      },
    },
  }
</script>

<style scoped>

</style>
